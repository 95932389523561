import { sortBy } from "lodash-es";
import API from "../../helpers/API";
import {
  SAVE_ACCESSORY_SERVICE_TYPES,
  SAVE_DEVICE_SERVICE_TYPES,
  SAVE_DEVICE_STATUSES,
} from "../mutation-types";

export default {
  state: {
    accessoryServiceTypes: [],
    deviceServiceTypes: [],
    deviceStatuses: [],
  },
  mutations: {
    [SAVE_ACCESSORY_SERVICE_TYPES](state, payload) {
      state.accessoryServiceTypes = payload;
    },
    [SAVE_DEVICE_SERVICE_TYPES](state, payload) {
      state.deviceServiceTypes = payload;
    },
    [SAVE_DEVICE_STATUSES](state, payload) {
      state.deviceStatuses = payload;
    },
  },
  actions: {
    /**
     * Device
     */
    getAllDevices: async (_, payload = null) => {
      return API.getAllWithDispatch("getDevices", payload);
    },
    getDevices: async (_, payload) => {
      let url = `devices?page=${payload.page ?? 1}`;

      if (payload.serialNumber) {
        url += `&serialNumber=${payload.serialNumber}`;
      }
      if (payload.product) {
        url += `&product=${payload.product}`;
      }
      if (payload.assignedTo) {
        url += `&assignedTo=${payload.assignedTo}`;
      }
      if (payload.assignedToName) {
        url += `&assignedTo.name=${payload.assignedToName}`;
      }

      return API.getMapped(url);
    },
    getDevice: async (_, id) => {
      return API.get(`devices/${id}`);
    },
    createDevice: async (_, device) => {
      return API.create("devices", device);
    },
    updateDevice: async (_, { id, device }) => {
      return API.update(`devices/${id}`, device);
    },

    /**
     * Device calibration certificate
     */
    uploadDeviceCalibrationCertificate: async (_, { id, payload }) => {
      return API.create(
        `devices/${id}/device_calibration_certificates`,
        payload
      );
    },

    /**
     * Device service
     */
    getAllDeviceServices: async (_, deviceId = null) => {
      return API.getAllWithDispatch("getDeviceServices", { deviceId }, "date");
    },
    getDeviceServices: async (_, payload) => {
      let url = `device_services?page=${payload.page ?? 1}`;

      if (payload.deviceId) {
        url += `&device=${payload.deviceId}`;
      }

      return API.getMapped(url);
    },
    createDeviceService: async (_, deviceService) => {
      return API.create("device_services", deviceService);
    },
    updateDeviceService: async (_, { id, deviceService }) => {
      return API.update(`device_services/${id}`, deviceService);
    },

    /**
     * Device service type
     */
    getDeviceServiceTypes: async ({ commit }) => {
      commit(
        SAVE_DEVICE_SERVICE_TYPES,
        await API.getAll("device_service_types")
      );
    },
    createDeviceServiceType: async ({ dispatch }, deviceServiceType) => {
      API.create("device_service_types", deviceServiceType).then(() => {
        dispatch("getDeviceServiceTypes");
      });
    },
    updateDeviceServiceType: async (
      { dispatch },
      { id, deviceServiceType }
    ) => {
      API.update(`device_service_types/${id}`, deviceServiceType).then(() => {
        dispatch("getDeviceServiceTypes");
      });
    },

    /**
     * Device status
     */
    getDeviceStatuses: async ({ commit }) => {
      commit(SAVE_DEVICE_STATUSES, await API.getAll("device_statuses"));
    },
    createDeviceStatus: async ({ dispatch }, deviceStatus) => {
      API.create("device_statuses", deviceStatus).then(() => {
        dispatch("getDeviceStatuses");
      });
    },
    updateDeviceStatus: async ({ dispatch }, { id, deviceStatus }) => {
      API.update(`device_statuses/${id}`, deviceStatus).then(() => {
        dispatch("getDeviceStatuses");
      });
    },

    /**
     * Product
     */
    getAllProducts: async () => {
      return API.getAllWithDispatch("getProducts");
    },
    getProducts: async (_, payload) => {
      return API.getMapped(`products?page=${payload.page ?? 1}`);
    },
    getProduct: async (_, id) => {
      return API.get(`products/${id}`);
    },
    createProduct: async (_, product) => {
      return API.create("products", product);
    },
    updateProduct: async (_, { id, product }) => {
      return API.update(`products/${id}`, product);
    },

    /**
     * Accessory
     */
    getAccessories: async (_, payload) => {
      let url = `accessories?page=${payload.page ?? 1}`;

      if (payload.name) {
        url += `&name=${payload.name}`;
      }
      if (payload.productType) {
        url += `&productType=${payload.productType}`;
      }
      if (payload.assignedTo) {
        url += `&assignedTo=${payload.assignedTo}`;
      }

      return API.getMapped(url);
    },
    getAccessory: async (_, id) => {
      return API.get(`accessories/${id}`);
    },
    createAccessory: async (_, accessory) => {
      return API.create("accessories", accessory);
    },
    updateAccessory: async (_, { id, accessory }) => {
      return API.update(`accessories/${id}`, accessory);
    },

    /**
     * Product type
     */
    getAllProductTypes: async () => {
      return API.getAllWithDispatch("getProductTypes");
    },
    getProductTypes: async (_, payload) => {
      return API.getMapped(`product_types?page=${payload.page ?? 1}`);
    },
    getProductType: async (_, id) => {
      return API.get(`product_types/${id}`);
    },
    createProductType: async (_, productType) => {
      return API.create("product_types", productType);
    },
    updateProductType: async (_, { id, productType }) => {
      return API.update(`product_types/${id}`, productType);
    },

    /**
     * Accessory service
     */
    getAllAccessoryServices: async (_, accessoryId = null) => {
      return API.getAllWithDispatch(
        "getAccessoryServices",
        { accessoryId },
        "date"
      );
    },
    getAccessoryServices: async (_, payload) => {
      let url = `accessory_services?page=${payload.page ?? 1}`;

      if (payload.accessoryId) {
        url += `&accessory=${payload.accessoryId}`;
      }

      return API.getMapped(url);
    },
    createAccessoryService: async (_, accessoryService) => {
      return API.create("accessory_services", accessoryService);
    },
    updateAccessoryService: async (_, { id, accessoryService }) => {
      return API.update(`accessory_services/${id}`, accessoryService);
    },

    /**
     * Accessory service type
     */
    getAccessoryServiceTypes: async ({ commit }) => {
      commit(
        SAVE_ACCESSORY_SERVICE_TYPES,
        await API.getAll("accessory_service_types")
      );
    },
    createAccessoryServiceType: async ({ dispatch }, accessoryServiceType) => {
      API.create("accessory_service_types", accessoryServiceType).then(() => {
        dispatch("getAccessoryServiceTypes");
      });
    },
    updateAccessoryServiceType: async (
      { dispatch },
      { id, accessoryServiceType }
    ) => {
      API.update(`accessory_service_types/${id}`, accessoryServiceType).then(
        () => {
          dispatch("getAccessoryServiceTypes");
        }
      );
    },
  },
  getters: {
    accessoryServiceTypesOrderedByName: (state) =>
      sortBy(state.accessoryServiceTypes, "name"),
    findAccessoryServiceTypeByUri: (state) => (uri) =>
      state.accessoryServiceTypes.find((d) => d["@id"] === uri),
    findDeviceServiceTypeByUri: (state) => (uri) =>
      state.deviceServiceTypes.find((d) => d["@id"] === uri),
    findDeviceStatusByUri: (state) => (uri) =>
      state.deviceStatuses.find((d) => d["@id"] === uri),
    deviceServiceTypesOrderedByName: (state) =>
      sortBy(state.deviceServiceTypes, "name"),
    deviceStatusesOrderedByName: (state) =>
      sortBy(state.deviceStatuses, "name"),
  },
};
