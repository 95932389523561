export default [
  {
    path: "/companies",
    name: "CompanyIndex",
    component: () => import("../../views/companies/CompanyIndex"),
    meta: {
      name: "company",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/companies/create",
    name: "CompanyCreate",
    component: () => import("../../views/companies/CompanyCreate"),
    meta: {
      name: "company_create",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/companies/:id",
    name: "CompanyView",
    component: () => import("../../views/companies/CompanyView"),
    meta: {
      name: "company_view",
      roles: ["ROLE_SUPERADMIN"],
    },
    props: true,
  },
  {
    path: "/companies/:id/edit",
    name: "CompanyEdit",
    component: () => import("../../views/companies/CompanyEdit"),
    meta: {
      name: "company_edit",
      roles: ["ROLE_SUPERADMIN"],
    },
    props: true,
  },
];
