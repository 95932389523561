import axios from "axios";
import lscache from "lscache";
import API from "../../helpers/API";
import router from "../../router";
import { SAVE_ENTITY, SAVE_USER } from "../mutation-types";

export default {
  state: {
    tokenRefresh: null,
    user: API.decodeToken(),
    entity: lscache.get("entity_full"),
  },
  mutations: {
    [SAVE_USER](state, user) {
      state.user = user;
    },
    [SAVE_ENTITY](state, entity) {
      if (!entity) {
        state.entity = null;

        lscache.remove("entity_full");
        lscache.set("jwt", lscache.get("jwt_global"));

        return;
      }

      state.entity = entity;

      lscache.set("entity_full", {
        ["@id"]: entity["@id"],
        id: entity.id,
        name: entity.name,
      });
    },
  },
  actions: {
    clearEntity: async ({ commit }) => {
      commit(SAVE_ENTITY, null);
    },
    login: async ({ commit }, payload) => {
      return axios.post("authentication_token", payload).then((response) => {
        lscache.set("jwt", response.data.token);
        lscache.set("jwt_global", response.data.token);

        commit(SAVE_USER, API.decodeToken());
      });
    },
    logout: ({ commit }) => {
      lscache.remove("jwt");
      lscache.remove("jwt_global");

      commit(SAVE_USER, null);
    },
    refreshToken: async () => {
      const jwt = lscache.get("jwt");
      const jwtGlobal = lscache.get("jwt_global");

      await axios
        .post("refresh_token", jwt, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          lscache.set("jwt", response.data.token);
        });

      // When logged in as super admin, both tokens will be equal
      // and we'll only need to refresh once
      if (jwt === jwtGlobal) {
        lscache.set("jwt_global", lscache.get("jwt"));
        return;
      }

      await axios
        .post("refresh_token", jwtGlobal, {
          headers: {
            Authorization: `Bearer ${jwtGlobal}`,
          },
        })
        .then((response) => {
          lscache.set("jwt_global", response.data.token);
        });
    },
    resetPasswordRequest: async (_, payload) => {
      const resetPasswordRoute = router
        .getRoutes()
        .find((r) => r.name === "ResetPassword");

      return axios
        .post("reset-password", {
          ...payload,
          callbackUrl: window.origin + resetPasswordRoute.path,
        })
        .catch((error) => {
          if (error.response.status === 422) {
            return Promise.reject(new Error("TOO_MANY_ATTEMPTS"));
          }
          throw error;
        });
    },
    resetPasswordSubmit: async (_, payload) => {
      return axios.post("reset-password/reset", {
        token: payload.token,
        password: payload.password,
      });
    },
    selectEntity: async ({ commit }, company) => {
      if (company.name === "Reduct N.V.") {
        commit(SAVE_ENTITY, company);
        return;
      }

      return API.create(`select_company/${company.id}`).then((response) => {
        lscache.set("jwt", response.token);

        commit(SAVE_ENTITY, company);
        commit(SAVE_USER, API.decodeToken());
      });
    },
  },
  getters: {
    userHasRolePartner: (state) => {
      return state.user?.roles.includes("ROLE_PARTNER");
    },
    userHasRoleCompanyUser: (state) => {
      return state.user?.roles.includes("ROLE_COMPANY_USER");
    },
    userHasRoleCompanyAdmin: (state) => {
      return state.user?.roles.includes("ROLE_COMPANY_ADMIN");
    },
    userHasRoleSuperAdmin: (state) => {
      return state.user?.roles.includes("ROLE_SUPERADMIN");
    },
  },
};
