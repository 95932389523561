export default [
  {
    path: "/xviewmetadata",
    name: "MetadataIndex",
    component: () => import("../../views/metadata/MetadataIndex"),
    meta: {
      name: "metadata",
    },
  },
];
