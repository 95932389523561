import API from "../../helpers/API";

export default {
  actions: {
    getBillingInformation: async (_, id) => {
      return API.get(`billing_informations/${id}`);
    },
    createBillingInformation: async (_, billingInformation) => {
      return API.create("billing_informations", billingInformation);
    },
    updateBillingInformation: async (_, { id, billingInformation }) => {
      return API.update(`billing_informations/${id}`, billingInformation);
    },
  },
};
