export default [
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../../views/settings/Settings"),
    meta: {
      name: "settings",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
];
