export default [
  {
    path: "/report/performance",
    name: "ReportPerformance",
    component: () => import("../../views/report/ReportPerformance"),
    meta: {
      name: "reports_performance",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/report/projects",
    name: "ReportProjects",
    component: () => import("../../views/report/ReportProjects"),
    meta: {
      name: "reports_projects",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/report/royalties",
    name: "ReportRoyalties",
    component: () => import("../../views/report/ReportRoyalties"),
    meta: {
      name: "reports_royalties",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/report/devices",
    name: "ReportDevices",
    component: () => import("../../views/report/ReportDevices"),
    meta: {
      name: "reports_devices",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
  },
];
