export const SAVE_ACCESSORY_SERVICE_TYPES = "SAVE_ACCESSORY_SERVICE_TYPES";
export const SAVE_CURRENCIES = "SAVE_CURRENCIES";
export const SAVE_DEVICE_SERVICE_TYPES = "SAVE_DEVICE_SERVICE_TYPES";
export const SAVE_DEVICE_STATUSES = "SAVE_DEVICE_STATUSES";
export const SAVE_ENTITY = "SAVE_ENTITY";
export const SAVE_LANGUAGES = "SAVE_LANGUAGES";
export const SAVE_LOADING = "SAVE_LOADING";
export const SAVE_PARTNER_ROLES = "SAVE_PARTNER_ROLES";
export const SAVE_SYSTEM_OF_MEASUREMENTS = "SAVE_SYSTEM_OF_MEASUREMENTS";
export const SAVE_USER = "SAVE_USER";
