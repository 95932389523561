<template>
  <footer>
    &copy;
    <a href="https://reduct.net/" target="_blank">{{ $t("reduct_full") }}</a>
    {{ year }}
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
