import API from "../../helpers/API";

export default {
  actions: {
    getAllUsers: async () => {
      return API.getAllWithDispatch("getUsers", null, "email");
    },
    getUsers: async (_, payload) => {
      let url = `users?page=${payload.page ?? 1}&operator=or`;

      if (payload.name) {
        url += `&name=${payload.name}`;
      }
      if (payload.emailAddress) {
        url += `&emailAddress=${payload.emailAddress}`;
      }

      return API.getMapped(url);
    },
    getUser: async (_, id) => {
      return API.get(`users/${id}`);
    },
    checkIfUserExists: async (_, email) => {
      return API.get(
        `users/by_email_address?emailAddress=${encodeURIComponent(email)}`
      ).catch((error) => {
        if (error.response.status === 404) {
          return null;
        }
      });
    },
    createUser: async (_, user) => {
      return API.create("users", user);
    },
    updateUser: async (_, { id, user }) => {
      return API.update(`users/${id}`, user);
    },
    deleteUser: async (_, id) => {
      return API.delete(`users/${id}`);
    },
  },
};
