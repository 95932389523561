export default [
  {
    path: "/devices",
    name: "DeviceIndex",
    component: () => import("../../views/devices/DeviceIndex"),
    meta: {
      name: "device",
    },
  },
  {
    path: "/devices/create",
    name: "DeviceCreate",
    component: () => import("../../views/devices/DeviceCreate"),
    meta: {
      name: "device_create",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/devices/:id",
    name: "DeviceView",
    component: () => import("../../views/devices/DeviceView"),
    meta: {
      name: "device_view",
    },
    props: true,
  },
  {
    path: "/devices/:id/edit",
    name: "DeviceEdit",
    component: () => import("../../views/devices/DeviceEdit"),
    meta: {
      name: "device_edit",
      roles: ["ROLE_SUPERADMIN"],
    },
    props: true,
  },
  {
    path: "/accessories/create",
    name: "AccessoryCreate",
    component: () => import("../../views/devices/AccessoryCreate"),
    meta: {
      name: "accessory_create",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/accessories/:id",
    name: "AccessoryView",
    component: () => import("../../views/devices/AccessoryView"),
    meta: {
      name: "accessory_view",
    },
    props: true,
  },
  {
    path: "/accessories/:id/edit",
    name: "AccessoryEdit",
    component: () => import("../../views/devices/AccessoryEdit"),
    meta: {
      name: "accessory_edit",
      roles: ["ROLE_SUPERADMIN"],
    },
    props: true,
  },
];
