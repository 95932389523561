import API from "../../helpers/API";

export default {
  actions: {
    getAllContracts: async (_, payload = null) => {
      return API.getAllWithDispatch("getContracts", payload);
    },
    getContracts: async (_, payload) => {
      let url = `contracts?page=${payload.page ?? 1}`;

      if (payload.contractNumber) {
        url += `&contractNumber=${payload.contractNumber}`;
      }
      if (payload.company) {
        url += `&company=${payload.company}`;
      }
      if (payload.endDateAfter) {
        url += `&endDate[after]=${payload.endDateAfter}`;
      }
      if (payload.nextContractEvaluationDateBefore) {
        url += `&nextContractEvaluationDate[before]=${payload.nextContractEvaluationDateBefore}`;
      }
      if (payload.nextContractEvaluationDateAfter) {
        url += `&nextContractEvaluationDate[after]=${payload.nextContractEvaluationDateAfter}`;
      }
      if (payload.orderBy) {
        url += `&order[${payload.orderBy}]=${payload.orderDirection ?? "asc"}`;
      }

      return API.getMapped(url);
    },
    getContract: async (_, id) => {
      return API.get(`contracts/${id}`);
    },
    createContract: async (_, contract) => {
      return API.create("contracts", contract);
    },
    updateContract: async (_, { id, contract }) => {
      return API.update(`contracts/${id}`, contract);
    },
  },
};
