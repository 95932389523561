export default [
  {
    path: "/partners",
    name: "PartnerIndex",
    component: () => import("../../views/partners/PartnerIndex"),
    meta: {
      name: "partner",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/partners/:id",
    name: "PartnerView",
    component: () => import("../../views/partners/PartnerView"),
    meta: {
      name: "partner_view",
      roles: ["ROLE_SUPERADMIN"],
    },
    props: true,
  },
];
