import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Vue from "vue";

Vue.prototype.$bugnagEnabled = process.env.NODE_ENV === "production";

if (Vue.prototype.$bugnagEnabled) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.VUE_APP_BUGSNAG_RELEASE,
    enabledReleaseStages: ["development", "production"],
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}
