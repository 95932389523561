<template>
  <div class="card download-modal">
    <b-loading v-model="loading"></b-loading>

    <div class="card-header">
      <div class="card-header-title">
        {{ $t("download_xview") }}
      </div>
    </div>
    <div class="card-content is-flex is-flex-direction-column">
      <a
        v-for="(download, i) in downloads"
        :key="i"
        :href="'https://reduct.net/downloads/XV/' + download.file"
        download
      >
        <span>
          {{ download.language }}
          <small>{{ download.version }}</small>
        </span>
        <b-icon icon="download" size="is-large"></b-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: true,
      downloads: [],
    };
  },
  async mounted() {
    this.getXviewDownloads().then((downloads) => {
      this.loading = false;
      this.downloads = downloads;
    });
  },
  methods: {
    ...mapActions(["getXviewDownloads"]),
  },
};
</script>
