<template>
  <aside>
    <b-button
      size="is-medium"
      type="is-text"
      icon-left="close"
      class="menu-toggle"
      @click="toggleMenu"
    ></b-button>
    <router-link :to="{ name: 'Home' }" class="logo">
      <img src="../../assets/images/logo.png" alt="Reduct logo" />
    </router-link>
    <b-menu :activable="false" class="navigation">
      <b-menu-list>
        <b-menu-item
          v-if="userHasRoleSuperAdmin"
          icon="briefcase"
          :label="$tc('company', 2)"
          tag="router-link"
          :to="{ name: 'CompanyIndex' }"
        ></b-menu-item>
        <b-menu-item
          v-if="userHasRoleSuperAdmin"
          icon="handshake"
          :label="$tc('partner', 2)"
          tag="router-link"
          :to="{ name: 'PartnerIndex' }"
        ></b-menu-item>
        <b-menu-item
          v-if="userHasRoleCompanyAdmin || userHasRoleSuperAdmin"
          icon="file-document-outline"
          :label="$tc('contract', 2)"
          tag="router-link"
          :to="{ name: 'ContractIndex' }"
        ></b-menu-item>
        <b-menu-item
          v-if="userHasRoleCompanyAdmin || userHasRoleSuperAdmin"
          icon="account"
          :label="$tc('user', 2)"
          tag="router-link"
          :to="{ name: 'UserIndex' }"
        ></b-menu-item>
        <b-menu-item
          icon="garage"
          :label="$tc('device', 2)"
          tag="router-link"
          :to="{ name: 'DeviceIndex' }"
        ></b-menu-item>
        <b-menu-item
          icon="ruler"
          :label="$t('metadata')"
          tag="router-link"
          :to="{ name: 'MetadataIndex' }"
        ></b-menu-item>
        <b-menu-item
          v-if="userHasRoleCompanyAdmin || userHasRoleSuperAdmin"
          :label="$t('reports')"
          icon="database"
          disabled
        >
          <b-menu-item
            :label="$t('performance')"
            tag="router-link"
            :to="{ name: 'ReportPerformance' }"
          ></b-menu-item>
          <b-menu-item
            :label="$t('projects')"
            tag="router-link"
            :to="{ name: 'ReportProjects' }"
          ></b-menu-item>
          <b-menu-item
            v-if="userHasRoleSuperAdmin"
            :label="$t('royalties')"
            tag="router-link"
            :to="{ name: 'ReportRoyalties' }"
          ></b-menu-item>
          <b-menu-item
            :label="$tc('device', 2)"
            tag="router-link"
            :to="{ name: 'ReportDevices' }"
          ></b-menu-item>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
    <b-menu :activable="false" class="external">
      <b-menu-list>
        <b-menu-item
          icon="school"
          :label="$t('reduct_academy')"
          href="https://reduct-academy.com/"
          target="_blank"
        ></b-menu-item>
        <b-menu-item
          icon="download"
          :label="$t('download_xview')"
          @click="showXviewModal = true"
        ></b-menu-item>
        <b-menu-item
          icon="android-messages"
          :label="$t('feedback')"
          href="mailto:cloud-support@reduct.net?subject=Feedback"
        ></b-menu-item>
        <b-menu-item
          icon="lifebuoy"
          :label="$t('launch_service_ticket')"
          href="mailto:cloud-support@reduct.net?subject=Service ticket"
        ></b-menu-item>
        <b-menu-item
          v-if="userHasRoleCompanyAdmin"
          icon="dolly"
          :label="$t('instructions')"
          href="https://reduct.net/en/shipping-reduct-probes-and-accessories-for-maintenance-and-repair/"
          target="_blank"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
    <b-modal v-model="showXviewModal" :width="400" scroll="keep">
      <XviewModal></XviewModal>
    </b-modal>
  </aside>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapState } from "vuex";
import XviewModal from "@/components/layout/partials/XviewModal.vue";

export default {
  components: { XviewModal },
  data() {
    return {
      showXviewModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "userHasRoleCompanyAdmin",
      "userHasRoleSuperAdmin",
      "userHasRoleCompanyUser",
    ]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    const sidebar = $("aside");

    // Close sidebar when clicking navigation item
    $("aside a").click(() => {
      sidebar.removeClass("toggled");
    });

    // Close sidebar when clicking outside it
    $(document).mouseup((e) => {
      if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {
        sidebar.removeClass("toggled");
      }
    });
  },
  methods: {
    toggleMenu() {
      $("aside").toggleClass("toggled");
    },
  },
};
</script>
