import API from "../../helpers/API";

export default {
  actions: {
    getAddress: async (_, id) => {
      return API.get(`addresses/${id}`);
    },
    createAddress: async (_, address) => {
      return API.create("addresses", address);
    },
    updateAddress: async (_, { id, address }) => {
      return API.update(`addresses/${id}`, address);
    },
  },
};
