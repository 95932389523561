import API from "../../helpers/API";

export default {
  actions: {
    /**
     * Company
     */
    getAllCompanies: async (_, payload = null) => {
      return API.getAllWithDispatch("getCompanies", payload);
    },
    getCompanies: async (_, payload) => {
      let url = `companies?page=${payload.page ?? 1}`;

      if (payload.name) {
        url += `&name=${payload.name}`;
      }
      // TODO: Enable when API supports OR
      // if (payload.vatNumber) {
      //   url += `&vatNumber=${payload.vatNumber}`;
      // }
      // if (payload.address) {
      //   url += `&address=${payload.address}`;
      // }
      if (payload.country) {
        url += `&address.country=${payload.country}`;
      }
      if (payload.region) {
        url += `&region=${payload.region}`;
      }
      if ("blocked" in payload) {
        url += `&blocked=${payload.blocked}`;
      }
      if (payload.orderBy) {
        url += `&order[${payload.orderBy}]=${payload.orderDirection ?? "asc"}`;
      }

      return API.getMapped(url);
    },
    getCompany: async (_, id) => {
      return API.get(`companies/${id}`);
    },
    createCompany: async (_, company) => {
      return API.create("companies", company);
    },
    updateCompany: async (_, { id, company }) => {
      return API.update(`companies/${id}`, company);
    },
    updateCompanyConsent: async (_, { id, payload }) => {
      return API.create(
        `companies/${id}/support_and_analysis_consent`,
        payload
      );
    },

    /**
     * Company user
     */
    getAllCompanyUsers: async (_, companyId = null) => {
      return API.getAllWithDispatch("getCompanyUsers", { companyId });
    },
    getCompanyUsers: async (_, payload) => {
      let url = `company_users?page=${payload.page ?? 1}`;

      if (payload.companyId) {
        url += `&company.id=${payload.companyId}`;
      }

      return API.getMapped(url);
    },
    createCompanyUser: async (_, companyUser) => {
      return API.create("company_users", companyUser);
    },
    updateCompanyUser: async (_, { id, companyUser }) => {
      return API.update(`company_users/${id}`, companyUser);
    },
  },
};
