import API from "../../helpers/API";

export default {
  actions: {
    getContact: async (_, id) => {
      return API.get(`contacts/${id}`);
    },
    createContact: async (_, contact) => {
      return API.create("contacts", contact);
    },
    updateContact: async (_, { id, contact }) => {
      return API.update(`contacts/${id}`, contact);
    },
  },
};
