import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { intersection } from "lodash-es";
import lscache from "lscache";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import admin from "./routes/admin-routes.js";
import auth from "./routes/auth-routes";
import company from "./routes/company-routes";
import contract from "./routes/contract-routes";
import device from "./routes/device-routes";
import general from "./routes/general-routes";
import metadata from "./routes/metadata-routes";
import partner from "./routes/partner-routes";
import report from "./routes/report-routes";
import user from "./routes/user-routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...admin,
    ...auth,
    ...company,
    ...contract,
    ...device,
    ...general,
    ...metadata,
    ...partner,
    ...report,
    ...user,
  ],
});

router.beforeEach((to, from, next) => {
  // Proceed if route is public
  if (to.meta.public) {
    next();
    return;
  }

  // Get token from local storage if route is not public
  const token = lscache.get("jwt");

  // Redirect to login if no token found
  if (!token) {
    next({ name: "Login" });
    return;
  }

  const decoded = jwtDecode(token);

  // Redirect to login if token is expired, remove token from local storage and clear auth in store
  if (dayjs().unix() >= decoded.exp) {
    store.dispatch("logout");
    next({ name: "Login", params: { expired: true } });
    return;
  }

  // Redirect to entity select if entity not yet set
  if (!lscache.get("entity_full") && to.name !== "SelectEntity") {
    next({ name: "SelectEntity" });
    return;
  }

  // Disallow if user doesn't have required role
  if (to.meta.roles) {
    // Check intersection between required roles for route and user's roles
    if (intersection(to.meta.roles, decoded.roles).length === 0) {
      console.error("Not allowed");
      return;
    }
  }

  next();
});

export default router;
