import API from "../../helpers/API";
import { SAVE_PARTNER_ROLES } from "../mutation-types";

export default {
  state: {
    partnerRoles: [],
  },
  mutations: {
    [SAVE_PARTNER_ROLES](state, payload) {
      state.partnerRoles = payload;
    },
  },
  actions: {
    /**
     * Partner
     */
    getAllPartners: async (_, payload = null) => {
      return API.getAllWithDispatch("getPartners", payload);
    },
    getPartners: async (_, payload) => {
      let url = `partnerships?page=${payload.page ?? 1}`;

      if (payload.partnerRole) {
        url += `&partnerRole=${payload.partnerRole}`;
      }
      if (payload.company) {
        url += `&company=${payload.company}`;
      }
      if (payload.contract) {
        url += `&contract=${payload.contract}`;
      }

      return API.getMapped(url);
    },
    getPartner: async (_, id) => {
      return API.get(`partnerships/${id}`);
    },
    createPartner: async (_, partner) => {
      return API.create("partnerships", partner);
    },

    /**
     * Partner role
     */
    getPartnerRoles: async ({ commit }) => {
      commit(SAVE_PARTNER_ROLES, await API.getAll("partner_roles"));
    },
    createPartnerRole: async ({ dispatch }, partnerRole) => {
      return API.create("partner_roles", partnerRole).then(() => {
        dispatch("getPartnerRoles");
      });
    },
    updatePartnerRole: async ({ dispatch }, { id, partnerRole }) => {
      return API.update(`partner_roles/${id}`, partnerRole).then(() => {
        dispatch("getPartnerRoles");
      });
    },
  },
  getters: {
    findPartnerRoleByUri: (state) => (uri) =>
      state.partnerRoles.find((p) => p["@id"] === uri),
    partnerRolesInCustomOrder: (state) => {
      const order = [
        "Sales commission",
        "Local logistics",
        "Support commission",
        "Calibration commission",
        "Hardware provider",
        "Reduct hardware",
        "Cloud service provider",
      ];

      return [...state.partnerRoles].sort(
        (a, b) => order.indexOf(a.name) - order.indexOf(b.name)
      );
    },
  },
};
