import store from "../../store";

export default [
  {
    path: "/users",
    name: "UserIndex",
    component: () => import("../../views/users/UserIndex"),
    meta: {
      name: "user",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: () => {
      const userIsSuperAdmin = store.getters.userHasRoleSuperAdmin;
      if (userIsSuperAdmin) {
        return import("../../views/users/UserCreateForSuperAdmin");
      }
      return import("../../views/users/UserCreateForCompanyAdmin");
    },
    meta: {
      name: "user_create",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/users/:id",
    name: "UserView",
    component: () => import("../../views/users/UserView"),
    meta: {
      name: "user_view",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
    props: true,
  },
  {
    path: "/users/:id/edit",
    name: "UserEdit",
    component: () => import("../../views/users/UserEdit"),
    meta: {
      name: "user_edit",
      roles: ["ROLE_SUPERADMIN"],
    },
    props: true,
  },
];
