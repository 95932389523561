export default [
  {
    path: "/contracts",
    name: "ContractIndex",
    component: () => import("../../views/contracts/ContractIndex"),
    meta: {
      name: "contract",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/contracts/create",
    name: "ContractCreate",
    component: () => import("../../views/contracts/ContractCreate"),
    meta: {
      name: "contract_create",
      roles: ["ROLE_SUPERADMIN"],
    },
  },
  {
    path: "/contracts/:id",
    name: "ContractView",
    component: () => import("../../views/contracts/ContractView"),
    meta: {
      name: "contract_view",
      roles: ["ROLE_COMPANY_ADMIN", "ROLE_SUPERADMIN"],
    },
    props: true,
  },
];
