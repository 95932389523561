export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../../views/auth/Login"),
    meta: {
      public: true,
      name: "login",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../../views/auth/ForgotPassword"),
    meta: {
      public: true,
      name: "password_forgot",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../../views/auth/ResetPassword"),
    meta: {
      public: true,
      name: "password_reset",
    },
  },
  {
    path: "/select-entity",
    name: "SelectEntity",
    component: () => import("../../views/auth/SelectEntity"),
    meta: {
      public: true,
      name: "select-entity",
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../../views/auth/Account"),
    meta: {
      name: "account",
    },
  },
  {
    path: "/account/edit",
    name: "AccountEdit",
    component: () => import("../../views/auth/AccountEdit"),
    meta: {
      name: "account_edit",
    },
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("../../views/auth/Company"),
    meta: {
      name: "company",
    },
  },
];
