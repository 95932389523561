import axios from "axios";
import API from "../../helpers/API";
import {
  SAVE_CURRENCIES,
  SAVE_LANGUAGES,
  SAVE_LOADING,
  SAVE_SYSTEM_OF_MEASUREMENTS,
} from "../mutation-types";

export default {
  state: {
    currencies: [],
    languages: [],
    loading: false,
    systemOfMeasurements: [],
  },
  mutations: {
    [SAVE_CURRENCIES](state, payload) {
      state.currencies = payload;
    },
    [SAVE_LANGUAGES](state, payload) {
      state.languages = payload;
    },
    [SAVE_LOADING](state, payload) {
      state.loading = payload;
    },
    [SAVE_SYSTEM_OF_MEASUREMENTS](state, payload) {
      state.systemOfMeasurements = payload;
    },
  },
  actions: {
    /**
     * Currency
     */
    getCurrencies: async ({ commit }) => {
      commit(SAVE_CURRENCIES, await API.getAll("currencies"));
    },
    createCurrency: async ({ dispatch }, currency) => {
      API.create("currencies", currency).then(() => {
        dispatch("getCurrencies");
      });
    },
    updateCurrency: async ({ dispatch }, { id, currency }) => {
      API.update(`currencies/${id}`, currency).then(() => {
        dispatch("getCurrencies");
      });
    },

    /**
     * Language
     */
    getLanguages: async ({ commit }) => {
      commit(SAVE_LANGUAGES, await API.getAll("languages"));
    },
    createLanguage: async ({ dispatch }, language) => {
      API.create("languages", language).then(() => {
        dispatch("getLanguages");
      });
    },
    updateLanguage: async ({ dispatch }, { id, language }) => {
      API.update(`languages/${id}`, language).then(() => {
        dispatch("getLanguages");
      });
    },

    /**
     * System of measurement
     */
    getSystemOfMeasurements: async ({ commit }) => {
      commit(
        SAVE_SYSTEM_OF_MEASUREMENTS,
        await API.getAll("system_of_measurements")
      );
    },
    createSystemOfMeasurement: async ({ dispatch }, systemOfMeasurement) => {
      API.create("system_of_measurements", systemOfMeasurement).then(() => {
        dispatch("getSystemOfMeasurements");
      });
    },
    updateSystemOfMeasurement: async (
      { dispatch },
      { id, systemOfMeasurement }
    ) => {
      API.update(`system_of_measurements/${id}`, systemOfMeasurement).then(
        () => {
          dispatch("getSystemOfMeasurements");
        }
      );
    },

    /**
     * Misc
     */
    setLoading: ({ commit }, loading) => {
      commit(SAVE_LOADING, loading);
    },
    getXviewDownloads: () => {
      return axios
        .get("https://reduct.net/downloads/XV/download_config.json")
        .then((response) => response.data);
    },
  },
  getters: {
    findCurrencyByUri: (state) => (uri) =>
      state.currencies.find((c) => c["@id"] === uri),
    findLanguageByISOCode: (state) => (isoCode) =>
      state.languages.find((l) => l.isoCode === isoCode),
    findSystemOfMeasurementByUri: (state) => (uri) =>
      state.systemOfMeasurements.find((s) => s["@id"] === uri),
  },
};
