import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { orderBy } from "lodash-es";
import lscache from "lscache";
import router from "../router";
import store from "../store";

export default class API {
  static stripId(id) {
    return id.replace(/\/.*\//, "");
  }

  static headers() {
    // Get token from local storage if route is not public
    const token = lscache.get("jwt");

    // Redirect to login if no token found
    if (!token) {
      router.push({ name: "Login" });
      return;
    }

    // Redirect to login if token is expired, remove token from local storage and clear auth in store
    if (!token || dayjs().unix() >= jwtDecode(token).exp) {
      store.dispatch("logout");
      router.push({ name: "Login", params: { expired: true } });
      return;
    }

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  static async getMapped(endpoint) {
    return axios.get(endpoint, API.headers()).then((response) => {
      return {
        items: response.data["hydra:member"],
        total: response.data["hydra:totalItems"],
      };
    });
  }

  static async get(endpoint) {
    return axios.get(endpoint, API.headers()).then((response) => {
      return response.data;
    });
  }

  static async download(endpoint) {
    return axios
      .get(endpoint, { ...API.headers(), responseType: "blob" })
      .then((response) => {
        if (response.status === 204) {
          return Promise.reject(new Error("NO_CONTENT"));
        }
        return response;
      });
  }

  static async create(endpoint, body) {
    return axios.post(endpoint, body || {}, API.headers()).then((response) => {
      return response.data;
    });
  }

  static async update(endpoint, body) {
    return axios.put(endpoint, body || {}, API.headers()).then((response) => {
      return response.data;
    });
  }

  static async delete(endpoint) {
    return axios.delete(endpoint, API.headers()).then((response) => {
      return response.data;
    });
  }

  static async getAll(endpoint) {
    const data = [];
    let page = 1;

    return new Promise((resolve) => {
      const cb = () => {
        axios
          .get(`${endpoint}?page=${page}`, API.headers())
          .then((response) => {
            data.push(...response.data["hydra:member"]);
            page++;

            if (data.length >= response.data["hydra:totalItems"]) {
              resolve();
              return data;
            }

            cb();
          });
      };
      cb();
    }).then(() => data);
  }

  static async getAllWithDispatch(
    action,
    payload,
    orderField = "name",
    orderDirection = "asc"
  ) {
    const data = [];
    let page = 1;

    return new Promise((resolve, reject) => {
      const cb = () => {
        payload = {
          ...payload,
          page,
        };

        store
          .dispatch(action, payload)
          .then((response) => {
            data.push(...response.items);
            page++;

            if (data.length >= response.total) {
              resolve();
              return;
            }

            cb();
          })
          .catch((error) => {
            reject(error);
          });
      };
      cb();
    }).then(() => {
      return orderBy(data, orderField, orderDirection);
    });
  }

  static decodeToken() {
    const token = lscache.get("jwt");
    if (!token) {
      return null;
    }

    const decoded = jwtDecode(token);
    return {
      id: decoded.id,
      username: decoded.username,
      roles: decoded.roles,
    };
  }
}
