import Vue from "vue";
import Vuex from "vuex";
import address from "./modules/address-module";
import auth from "./modules/auth-module";
import billingInformation from "./modules/billing-information-module";
import company from "./modules/company-module";
import contact from "./modules/contact-module";
import contract from "./modules/contract-module";
import device from "./modules/device-module";
import global from "./modules/global-module";
import metadata from "./modules/metadata-module";
import partner from "./modules/partner-module";
import user from "./modules/user-module";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    address,
    auth,
    billingInformation,
    company,
    contact,
    contract,
    device,
    global,
    metadata,
    partner,
    user,
  },
});
