import Bugsnag from "@bugsnag/js";
import axios from "axios";
import { SnackbarProgrammatic as Snackbar } from "buefy";
import Vue from "vue";
import i18n from "./i18n";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const message = error.response.data?.["hydra:description"];

    // Swallow "user not found" error
    if (error.response.config.url.includes("by_email_address")) {
      throw error;
    }

    Snackbar.open({
      message: message ?? i18n.t("something_went_wrong"),
      type: "is-danger",
      position: "is-top",
      actionText: message ? null : i18n.t("refresh"),
      indefinite: false,
      duration: 4000,
      onAction: () => location.reload(),
    });

    if (Vue.prototype.$bugnagEnabled) {
      Bugsnag.notify(error);
    }

    throw error;
  }
);
