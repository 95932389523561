<template>
  <header>
    <b-button
      size="is-medium"
      type="is-text"
      icon-left="menu"
      class="menu-toggle"
      @click="toggleMenu"
    ></b-button>
    <div v-if="user" class="account">
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <b-icon icon="account" class="account-icon"></b-icon>
          <div class="account-info">
            <span class="account-user">{{ user.username }}</span>
            <span class="account-company">{{ entity.name }}</span>
          </div>
          <b-icon
            :icon="active ? 'chevron-up' : 'chevron-down'"
            class="toggle-icon"
          ></b-icon>
        </template>
        <b-dropdown-item aria-role="listitem" has-link>
          <router-link :to="{ name: 'Account' }">
            {{ $t("account") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userHasRoleCompanyAdmin"
          aria-role="listitem"
          has-link
        >
          <router-link :to="{ name: 'Company' }">
            {{ $tc("company", 1) }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!userHasRoleSuperAdmin"
          aria-role="listitem"
          has-link
        >
          <router-link :to="{ name: 'SelectEntity' }">
            {{ $t("switch_entity") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userHasRoleSuperAdmin"
          aria-role="listitem"
          has-link
        >
          <router-link :to="{ name: 'Settings' }">
            {{ $t("settings") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="submitLogout">
          {{ $t("logout") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </header>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["userHasRoleCompanyAdmin", "userHasRoleSuperAdmin"]),
    ...mapState({
      entity: (state) => state.auth.entity,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
    toggleMenu() {
      $("aside").toggleClass("toggled");
    },
    submitLogout() {
      this.$router.push({ name: "Login" });
      this.logout();
    },
  },
};
</script>
