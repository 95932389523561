<template>
  <div id="app" :class="pageClass">
    <b-loading v-model="loading"></b-loading>
    <Sidebar v-if="!loading && privateRoute && loggedIn"></Sidebar>
    <main v-if="!loading">
      <Header v-if="privateRoute && loggedIn"></Header>
      <div class="container is-fluid">
        <router-view />
      </div>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
import Bugsnag from "@bugsnag/js";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";

export default {
  name: "App",
  components: {
    Footer,
    Header,
    Sidebar,
  },
  data() {
    return {
      loggedIn: false,
      tokenTimer: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.global.loading,
      user: (state) => state.auth.user,
    }),
    pageClass() {
      if (!this.$route.name) {
        return;
      }

      // Parse route name from PascalCase to kebab-case
      const kebab =
        this.$route.name.charAt(0).toLowerCase() +
        this.$route.name
          .substring(1)
          .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

      return `page-${kebab}`;
    },
    privateRoute() {
      return !this.$route.meta.public;
    },
  },
  watch: {
    "$route.meta.name": {
      immediate: true,
      handler(value) {
        let title = this.$t("reduct_cloud");
        if (value && this.$te(value)) {
          title += ` - ${this.$tc(value, 2)}`;
        }
        document.title = title;
      },
    },
    user: {
      immediate: true,
      async handler(newValue, oldValue) {
        this.loggedIn = !!newValue;

        // When not logged in (anymore), clear token refresh timer
        if (!newValue) {
          clearInterval(this.tokenTimer);
          return;
        }

        // When changing entity, don't reload static data
        if (oldValue) {
          return;
        }

        if (Vue.prototype.$bugnagEnabled) {
          Bugsnag.setUser(newValue.username);
        }

        // When loading in for the first time, load all static data
        this.setLoading(true);
        await Promise.all([
          this.getAccessoryServiceTypes(),
          this.getCurrencies(),
          this.getDeviceServiceTypes(),
          this.getDeviceStatuses(),
          this.getLanguages(),
          this.getPartnerRoles(),
          this.getSystemOfMeasurements(),
        ]);
        this.setLoading(false);

        // When loading in for the first time, start token refresh timer
        if (!this.tokenTimer) {
          this.tokenTimer = setInterval(this.refreshToken, 60000);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "getAccessoryServiceTypes",
      "getCurrencies",
      "getDeviceServiceTypes",
      "getDeviceStatuses",
      "getLanguages",
      "getPartnerRoles",
      "getSystemOfMeasurements",
      "refreshToken",
      "setLoading",
    ]),
  },
};
</script>
